import * as React from "react"
import cx from "classnames";
import {Button, CircularProgress, Container, Grid, TextField} from "@mui/material";
import * as styles from './Contact.module.scss';
import {Check} from "@mui/icons-material";

import {useState} from "react";
import {useI18next} from "gatsby-plugin-react-i18next";
import {validateEmail} from "../../utils/email";

const NEWSLETTER_API_URL = 'https://api.loggy.pl/api/newsletter'


const Contact = () => {
    const {language, t} = useI18next();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nameValue = event.currentTarget.value;
        setName(nameValue);
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = event.currentTarget.value;
        setEmail(emailValue);
    }

    const handleSubscribe = () => {
        if (name && email && validateEmail(email)) {
            setError(false);
            setIsLoading(true);
            fetch(NEWSLETTER_API_URL, {
                body: JSON.stringify({email, name, language}),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }).then(() => {
                setSuccess(true);
            }).catch(() => {
                setError(true)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }
    return (<div className={cx(styles.Contact)} id={'newsletter'}>
            <Container maxWidth={'lg'}>
                <Grid xs={12} className={cx(styles.PricingTitle)} justifyContent={'center'}>
                    <h3 className={styles.Title}>{t("newsletter.title1")} <strong>{t("newsletter.title2")}</strong></h3>
                    <p className={styles.Subtitle}>{t("newsletter.subtitle")}</p>
                    <form>
                        <Grid container={true} justifyContent={'center'} spacing={2}>
                            <Grid xs={12} md={3} item>
                                <TextField fullWidth={true} required={true} onChange={onNameChange} id="outlined-basic"
                                           label={t("newsletter.name")}
                                           variant="outlined"/>
                            </Grid>
                            <Grid xs={12} md={3} item>
                                <TextField fullWidth={true} type={'email'} onChange={onEmailChange} id="outlined-basic"
                                           label={t("newsletter.email")}
                                           required={true}
                                           variant="outlined"/>
                            </Grid>
                            <Grid xs={12} md={2} item>
                                <Button
                                    variant="contained"
                                    size={"large"}
                                    color="primary"
                                    onClick={handleSubscribe}
                                    type={'submit'}
                                    fullWidth={true}
                                    className={styles.SubmitButton}
                                    disabled={isLoading}
                                >
                                    <span className={cx({[styles.SuccessButtonText]: success})}>{t("newsletter.subscribe")}</span>
                                    {isLoading && <CircularProgress size={24} className={styles.Loader}/>}
                                    {success && <Check className={styles.Loader}/>}
                                </Button>
                            </Grid>

                            {error && <Grid xs={8} item>
                                <p className={styles.Error}>
                                    {t("newsletter.error")}
                                </p>
                            </Grid>}
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </div>
    )
}

export default Contact;
